<!--
 * @Author: John
 * @Date: 2022-03-08 09:41:30
 * @LastEditTime: 2022-09-23
 * @LastEditors: Please set LastEditors
 * @Description: 收益统计
 * @FilePath: \jjms_ui\src\views\dtsy\statistic.vue
 * @Copyright John
-->
<template>
    <div>
        <my-breadcrumb :data="breadcrumbData" />
        <div class="content-wrap">
            <div class="toolbar">
                <el-form :inline="true">
                    <jj-select v-model="jjSelectObj" />
                    <el-form-item label="统计维度">
                        <el-select v-model="type" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="query" icon="el-icon-search" style="margin-left: 20px;">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <span style="color:red; margin-left: 30px;">只展示最近的100条记录</span>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 基金净值图表 -->
            <div id="chartsWrap" style="height: 300px;"></div>

            <!-- 基金净值数据表 -->
            <el-table
                ref="syStatisticTable"
                :data="tableData"
                style="width: 100%"
                highlight-current-row
                @current-change="handleCurrentChange"
                >
                <el-table-column
                    width="50"
                    type="index"
                    label="序号"
                 />
                <el-table-column
                    prop="wd"
                    label="统计维度：时间/基金"
                />
                <el-table-column
                    prop="total"
                    label="累计收益"
                />
            </el-table>
        </div>
    </div>
</template>
<script>
import myBreadcrumb from '@/components/my-breadcrumb.vue'
import jjSelect from '@/components/jj-select.vue'

import api from '@/api/req_api.js'
import * as echarts from 'echarts'
let myChart = null;
export default {
    name: 'dtsy-statistic',  // 收益分析
    components: {
        myBreadcrumb,
        jjSelect
    },
    data() {
        return {
            breadcrumbData: [
                {title: '数据管理'},
                {title: '收益分析', url: '/main/dtsy-statistic'}
            ],
            tableData: null,
            type: 'by_default',  // 统计维度
            currentRow: null,
            options: [
                {value: 'all', label: '有史以来'},
                {value: 'by_year', label: '按年汇总'},
                {value: 'by_month', label: '按月汇总'},
                {value: 'by_day', label: '按天'},
                {value: 'by_default', label: '当前定投周期汇总'}
            ],
            jjSelectObj: null
        }
    },
    methods: {
        loadData(jjCode, type) {
            api.queryDtSyStatistic(type, jjCode).then(data => {
                if(data != null) {
                    this.tableData = data;
                } 
            });
        },
        query() {
            this.loadData(this.jjCode, this.type);
        },
        handleCurrentChange(currentRow) {
            this.currentRow = currentRow;
        },
        handleChange(val) {
            this.jjCode = val;
        }
    },
    mounted() {
        // 基于准备好的dom，初始化echarts实例
        myChart = echarts.init(document.getElementById('chartsWrap'));
        
        // 查询数据
        this.query();
    },
    computed: {
        jjCode() {
            console.log(this.jjSelectObj)
            if(!this.jjSelectObj) {
                return '';
            }
            return this.jjSelectObj.jjCode;
        }
    },
    watch: {
        tableData(newData) {
            if(!newData || newData.length < 1) return;
            // 反序
            let dateArr = [];
            let valueArr = [];
            for(let idx = newData.length - 1; idx >= 0; idx--) {
                dateArr.push(newData[idx].wd);
                valueArr.push(newData[idx].total);
            }
            // 绘制图表
            myChart.setOption({
                title: {
                    text: '收益趋势统计'
                },
                tooltip: {},
                xAxis: {
                    name: '统计维度：时间/基金',
                    data: dateArr
                },
                yAxis: {},
                series: [
                    {
                    name: '累计收益',
                    type: 'line',
                    // smooth: true,
                    data: valueArr
                    }
                ]
            });
        }
    }
}
</script>
